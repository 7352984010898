import { Injectable } from '@angular/core';
import { environment } from '~/environments/environment';
import { stringIsSetAndFilled } from '#/util/values';

export enum ApplicationName {
	KLIPPA = 'klippa',
	RADIUS = 'radius',
}

@Injectable({
	providedIn: 'root',
})
export class ComplianceUrlsService {
	public getApplicationName(applicationUrl: string): ApplicationName {
		switch (applicationUrl) {
			case 'https://app.veloscard.com':
			case 'https://app.test.radius.whitelabel.klippa.com':
				return ApplicationName.RADIUS;
			case 'https://app.klippa.com':
			case 'https://app-test.klippa.com':
			default:
				return ApplicationName.KLIPPA;
		}
	}

	public getPrivacyStatement(): string {
		if (stringIsSetAndFilled(environment?.compliance?.privacy_statement)) {
			return environment?.compliance?.privacy_statement;
		}

		return 'https://www.klippa.com/en/terms-and-conditions/';
	}

	public getTermsAndConditionsStatement(): string {
		if (stringIsSetAndFilled(environment?.compliance?.terms_and_conditions_statement)) {
			return environment?.compliance?.terms_and_conditions_statement;
		}

		return 'https://www.klippa.com/en/terms-and-conditions/';
	}

	public getDataProcessingAgreementStatement(): string {
		if (stringIsSetAndFilled(environment?.compliance?.data_processing_agreement_statement)) {
			return environment?.compliance?.data_processing_agreement_statement;
		}

		return 'https://www.klippa.com/en/data-processing-agreement/';
	}
}
